import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import  Logo from '../images/logo.svg';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false, 
      pages: [
        {slug:"catalog", title: "Catalog"},
        {slug:"social", title: "Social"},
        {slug:"about-us", title: "About Us"},
        {slug:"contact", title: "Contact Us"}
      ]
    };
    this.onClick= this.onClick.bind(this);

  }
  onClick() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }
  render () {
    return (
      <header>
        <Link to="/" className="header__logo">
          <Logo alt="Visit Films" />
        </Link>
        <nav className={this.state.active ? 'nav--open': null}>
          {
            this.state.pages.map((page) =>
              <Link key={page.slug} to={"/" + page.slug} className={page.slug == this.props.page? "active": ""}><span>{page.title}</span></Link>
            )
          }
        </nav>  
        <div className={this.state.active ? 'menu-toggle open': 'menu-toggle'} onClick={this.onClick}>
          <div className="hamburger"></div>
        </div>
      </header>
    );    
  }
}
Header.propTypes = {
  page: PropTypes.string,
};

export default Header;

