import React, { Component } from "react"
import PropTypes from "prop-types"
// import { Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/index.scss"

class DefaultLayout extends Component {
  render() {
    let classes = this.props.classes == null ? this.props.page : this.props.classes;
    return (
      <div className={classes}>
        <Header page={this.props.page}/>
        {this.props.children}
        <Footer/>
        
      </div>
    )
  }
}

DefaultLayout.propTypes = {
  page: PropTypes.string,
  classes: PropTypes.string,
  children: PropTypes.array,
}

export default DefaultLayout
