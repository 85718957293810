import React from 'react';
import { Link } from "gatsby"
import logo from '../images/logo_vf.png';

const Footer = () => (
    <footer className="footer">
      <div className="container">
        <div>
          <div className="footer__social">
            <a href="https://www.facebook.com/visitfilms" target="_blank" rel="noopener noreferrer"><i className="footer--icon icon-facebook1" /></a>
            <a href="https://www.instagram.com/visit_films/" target="_blank" rel="noopener noreferrer"><i className="footer--icon icon-instagram" /></a>
            <a href="https://twitter.com/Visitfilms" target="_blank" rel="noopener noreferrer"><i className="footer--icon icon-twitter" /></a>
          </div>
          
        </div>
          
      </div>
    </footer>
  );
  
  export default Footer;